<template>
  <FormMainLayout>
    <router-view />
  </FormMainLayout>
</template>

<script>
import FormMainLayout from "@/layouts/FormMainLayout";

export default {
  components: {
    FormMainLayout
  },
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    }
  }
};
</script>
